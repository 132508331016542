<template>
	<div class="content flex-space-between">
		<div class="left">
			<AsideNavMenu :list="navMenuList" title="关于协会" @navMenuClick="navMenuClick"></AsideNavMenu>
		</div>
		<div class="right">
			<router-view :index="navIndex"></router-view>
		</div>
	</div>
</template>

<script>
import {EventBus} from '@/utils/event-bus'
import AsideNavMenu from '@/components/aside/navmenu.vue'

export default {
	components: {AsideNavMenu},
	data() {
		return {
			navMenuList: [
				{
					name: '协会简介',
					url: '/arich'
				},
				{
					name: '领导机构',
					url: '/arich'
				},
				{
					name: '协会章程',
					url: '/arich'
				},
				{
					name: '协会管理',
					url: '/arich'
				}
			],
			navIndex: 0
		}
	},

	mounted() {
		EventBus.$on('selectClick', (res) => {
			this.navIndex = res.index
		})
	},

	beforeDestroy() {
		EventBus.$off('selectClick')
	},

	methods: {
		navMenuClick(index) {
			this.navIndex = index
			this.$route.meta.asideNavMenuIndex = index
			if (index == 0) {
				this.$route.meta.pathName = '协会简介'
			} else if (index == 1) {
				this.$route.meta.pathName = '领导机构'
			} else if (index == 2) {
				this.$route.meta.pathName = '协会章程'
			} else if (index == 3) {
				this.$route.meta.pathName = '协会管理'
			}
		}
	}
}
</script>

<style lang="less" scoped>
.content {
	align-items: flex-start;
}

.right {
	flex: 1;
	margin-left: 20px;
	background: #fff;
	border-radius: 3px;
}
</style>
